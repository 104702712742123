import { ArrowUpTrayIcon, ComputerDesktopIcon, ArrowUturnUpIcon } from "@heroicons/react/24/solid";
import Header from "../../../components/common/Header";
import Button from "../../../components/ui/Button";

const Home = () => {

  return (
    <div className="h-screen w-full lg:pb-20 flex flex-col items-center bg-[#333333] overflow-y-scroll [-ms-overflow-style:none] [scrollbar-width:none]">
      <Header />
      <div className="mt-[50px]">
        <h1 className="text-[#FCD43C] font-serif text-3xl lg:text-5xl">ホーム</h1>
      </div>
      <div className="flex flex-col mt-[30px]">
        <Button btnTitle="アイテム登録" path="/add" >
          <ArrowUpTrayIcon className="size-12 md:size-16" />
        </Button>
        <Button btnTitle="借入アイテム一覧" path="/borrowlist" >
          <ComputerDesktopIcon className="size-12 md:size-16" />
        </Button>
        <Button btnTitle="返却済アイテム一覧" path="/returnedlist" >
          <ArrowUturnUpIcon className="size-12 md:size-16" />
        </Button>
      </div>
    </div>
  );
};

export default Home;
