import { useLocation, useNavigate } from "react-router-dom"
import { useReducer, useState } from "react";
import Complete from "./Complete";
import EditHeader from "../../../components/headers/EditHeader";

type itemData = {
  itemName: string;
  borrower: string;
  checkoutdate: string;
  locationOfUse: string;
  imagePath: string;
};

const Edit = () => {

  const navigate = useNavigate();
  const fromPath: string = "/detail";
  const location = useLocation();
  const { itemName, borrower, checkoutdate, locationOfUse, imagePath }: itemData = location.state;

  const [ initItemName, setInitItemName ] = useState<string>(itemName);
  const [ initBorrower, setInitBorrower ] = useState<string>(borrower);
  const [ initCheckoutdate, setInitCheckoutdate ] = useState<string>(checkoutdate);
  const [ initLocationOfUse, setInitLocationOfUse ] = useState<string>(locationOfUse);

  const [ completeModal, toggleCompleteModal ] = useReducer((state) => !state, false);

  return (
    <div className="w-full min-h-screen bg-[#333333] pb-10">
      <EditHeader fromPath={fromPath} itemName={itemName} borrower={borrower} checkoutdate={checkoutdate} locationOfUse={locationOfUse} imagePath={imagePath} />
      <div className="mt-[50px] text-center">
        <h1 className="text-[#FCD43C] font-serif text-xl md:text-3xl">アイテム情報の編集</h1>
      </div>
      <div className="flex items-center justify-center mt-10 md:mt-20">
        <div className="aspect-square border overflow-hidden size-44 md:size-72 lg:size-96">
          <img src={imagePath} className="size-full object-cover" />
        </div>
      </div>
      <form className="mt-10 flex item-center justify-center">
        <ul className="text-white font-serif w-[300px] md:w-[360px] lg:w-[420px] space-y-5">
          <li className="text-base md:text-xl flex items-center justify-between">
            <p>品名</p>
            <div className="bg-[#33363d]">            
              <input
              type="text"
              placeholder="品名"
              value={initItemName}
              name="itemName"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInitItemName(e.target.value)}
              className="text-center border bg-transparent border-solid border-gray-500 py-2 rounded-md outline-none"
              />
            </div>
          </li>
          <li className="text-base md:text-xl flex items-center justify-between">
            <p>貸出元</p>
            <div className="bg-[#33363d]">            
              <input
              type="text"
              placeholder="貸出元"
              value={initBorrower}
              name="borrower"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInitBorrower(e.target.value)}
              className="text-center border bg-transparent border-solid border-gray-500 py-2 rounded-md outline-none"
              />
            </div>
          </li>
          <li className="text-base md:text-xl flex items-center justify-between">
            <p>レンタル日</p>
            <div className="bg-[#33363d]">            
              <input
              type="text"
              placeholder="レンタル日"
              value={initCheckoutdate}
              name="checkoutdate"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInitCheckoutdate(e.target.value)}
              className="text-center border bg-transparent border-solid border-gray-500 py-2 rounded-md outline-none"
              />
            </div>
          </li>
          <li className="text-base md:text-xl flex items-center justify-between">
            <p>使用場所</p>
            <div className="bg-[#33363d]">            
              <input
              type="text"
              placeholder="使用場所"
              value={initLocationOfUse}
              name="locationOfUse"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInitLocationOfUse(e.target.value)}
              className="text-center border bg-transparent border-solid border-gray-500 py-2 rounded-md outline-none"
              />
            </div>
          </li>
        </ul>
      </form>
    <div className="flex flex-col items-center justify-center gap-7 mt-10 md:mt-12 lg:mt-16">
      <button onClick={toggleCompleteModal} className="w-[240px] md:w-[280px] py-[10px] px-[30px] md:py-[10px] md:px-[45px] text-[#171717] border-none rounded-[5px] text-base md:text-xl font-bold font-serif cursor-pointer transision duration-300 bg-[#FCD43C] hover:opacity-70">保存する</button>
      <button onClick={() => navigate("/detail", { state: { itemName, borrower, checkoutdate, locationOfUse, imagePath }})} className="w-[240px] md:w-[280px] py-[10px] px-[30px] md:py-[10px] md:px-[45px] text-[#171717] border-none rounded-[5px] text-base md:text-xl font-bold font-serif cursor-pointer transision duration-300 bg-gray-500 hover:opacity-70">キャンセルする</button>
      <button onClick={() => navigate("/delete", { state: { itemName, borrower, checkoutdate, locationOfUse, imagePath }})} className="w-[240px] md:w-[280px] py-[10px] px-[30px] md:py-[10px] md:px-[45px] text-[#171717] border-none rounded-[5px] text-base md:text-xl font-bold font-serif cursor-pointer transision duration-300 bg-red-400 hover:opacity-70">このアイテムを削除</button>
    </div>
    {completeModal && (
        <Complete />
      )}
  </div>
  )
}

export default Edit