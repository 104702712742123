import { UserCircleIcon } from "@heroicons/react/24/solid"
import { Link } from "react-router-dom"

type Props = {
  employeeName: string;
  placeOfEmployment: string;
  dateOfJoin: string;
}

const Employee = (props: Props) => {

  const { employeeName, placeOfEmployment, dateOfJoin } = props;
  
  return (
    <Link to="/employee-detail" state={{ employeeName, placeOfEmployment, dateOfJoin }} className="bg-white/10 h-24 md:h-32 flex items-center justify-center">
      <div className="flex items-center gap-10">
        <UserCircleIcon className="w-20 h-20 md:w-28 md:h-28 text-white" />
        <div className="text-white text-base md:text-lg font-bold font-serif space-y-1">
          <p>{employeeName}</p>
          <p>{placeOfEmployment}</p>
        </div>
      </div>
    </Link>
  )
}

export default Employee