import { useLocation, useNavigate } from "react-router-dom";
import DetailHeader from "../../components/headers/DetailHeader";

type itemData = {
  itemName: string;
  borrower: string;
  checkoutdate: string;
  locationOfUse: string;
  imagePath: string;
};

const Detail = () => {

  const navigate = useNavigate();
  const fromPath: string = "/borrowlist"
  const location = useLocation();
  const { itemName, borrower, checkoutdate, locationOfUse, imagePath }: itemData = location.state;

  return (
    <div className="w-full min-h-screen bg-[#333333] pb-10">
      <DetailHeader fromPath={fromPath} />
      <div className="mt-[50px] text-center">
        <h1 className="text-[#FCD43C] font-serif text-xl md:text-3xl">アイテム詳細</h1>
      </div>
      <div className="flex flex-col items-center justify-center mt-10">
        <div className="aspect-square border overflow-hidden size-72 md:size-[400px]">
          <img src={imagePath} className="size-full object-cover" />
        </div>
        <div className="mt-10">
          <ul className="text-white font-serif w-[300px] md:w-[360px] lg:w-[420px]">
            <li className="text-xl md:text-2xl flex items-center justify-between">
              <p>品名:</p><p>{itemName}</p>
            </li>
            <li className="text-xl md:text-2xl flex items-center justify-between">
              <p>貸出元:</p><p>{borrower}</p>
            </li>
            <li className="text-xl md:text-2xl flex items-center justify-between">
              <p>レンタル日:</p><p>{checkoutdate}</p>
            </li>
            <li className="text-xl md:text-2xl flex items-center justify-between">
              <p>使用場所:</p><p>{locationOfUse}</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex items-center justify-center gap-5 mt-8 md:mt-12 lg:mt-16">
        <button onClick={() => navigate("/edit", { state: { itemName, borrower, checkoutdate, locationOfUse, imagePath } })} className="py-[5px] px-[30px] md:py-[10px] md:px-[45px] lg:py-[18px] lg:px-[60px] text-[#171717] border-none rounded-[5px] text-base md:text-xl font-bold font-serif cursor-pointer transision duration-300 bg-gray-300 hover:opacity-70">編集</button>
        <button onClick={() => navigate("/return")} className="py-[5px] px-[30px] md:py-[10px] md:px-[45px] lg:py-[18px] lg:px-[60px] text-[#171717] border-none rounded-[5px] text-base md:text-xl font-bold font-serif cursor-pointer transision duration-300 bg-[#FCD43C] hover:opacity-70">返却する</button>
      </div>
    </div>
  )
}

export default Detail