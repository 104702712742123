import { ItemInfoType } from "./Type";
import "animate.css";

const Check = ({ formValues, imageUrl, toggleCheckModal, toggleCompleteModal }: { formValues: ItemInfoType, imageUrl: string, toggleCheckModal: React.DispatchWithoutAction, toggleCompleteModal: React.DispatchWithoutAction }) => {
  
  return (
    <div className="w-full h-screen top-0 left-0 bottom-0 right-0 fixed z-20">
      <div className="bg-[rgba(49,49,49,0.8)] w-screen h-screen top-0 left-0 bottom-0 right-0 fixed"></div>
      <div className="w-full h-full mt-[80px] fixed bg-[#333333] border-[3px] border-solid border-[#FFFFFF] rounded-[30px] transision duration-800 overflow-scroll [-ms-overflow-style:none] [scrollbar-width:none] animate__animated animate__fadeInUp">
        <div className="flex flex-col items-center justify-center mt-[50px] mb-[70px]">
          <p className="text-center text-[#FCD43C] text-base font-bold font-serif">
            こちらの内容でよろしければ、<br/>
            登録ボタンを押してください
          </p>
          <div className="border border-solid border-[#FFFFFF] mt-10 w-[300px] h-[250px] md:w-[450px] md:h-[350px] lg:w-[550px] lg:h-[450px]">
            <img
              src={imageUrl}
              alt="アップロード画像"
              className="size-full object-cover"
              />
          </div>
          <div className="mt-[20px]">
            <ul>
              <li className="list-none text-[#FFFFFF] mt-[10px] text-base font-bold font-serif">レンタル日: <span className="text-[#FCD43C]">{formValues.checkoutDate}</span></li>
              <li className="list-none text-[#FFFFFF] mt-[10px] text-base font-bold font-serif">アイテム名: <span className="text-[#FCD43C]">{formValues.itemName}</span></li>
              <li className="list-none text-[#FFFFFF] mt-[10px] text-base font-bold font-serif">貸出元: <span className="text-[#FCD43C]">{formValues.borrower}</span></li>
              <li className="list-none text-[#FFFFFF] mt-[10px] text-base font-bold font-serif">使用場所: <span className="text-[#FCD43C]">{formValues.locationOfUse}</span></li>
            </ul>
          </div>
          <div className="flex items-center justify-center gap-5 mt-[50px] mb-[50px]">
            <button className="py-[15px] px-[50px] text-[#171717] border-none rounded-[10px] text-base font-bold font-serif cursor-pointer transision duration-300 bg-gray-300 hover:opacity-70" onClick={toggleCheckModal}>戻る</button>
            <button className="py-[15px] px-[50px] text-[#171717] border-none rounded-[10px] text-base font-bold font-serif cursor-pointer transision duration-300 bg-[#FCD43C] hover:opacity-70" onClick={toggleCompleteModal}>登録</button>
          </div>
        </div>
      </div>
    </div>
  );

}

export default Check