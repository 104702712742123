import { UserInfoType, ErrorInfoType, HandleChangeType, ValidateType, HandleSubmitType, ToggleModalType } from "./Type";
import { useState } from "react";
import { Link } from "react-router-dom";

const Reset = () => {

  const initialValues: UserInfoType = {
    mailaddress: ""
  };
  const initialErrors: ErrorInfoType = {
    mailaddress: ""
  };

  const [ formValues, setFormValues ] = useState<UserInfoType>(initialValues);
  const [ formErrors, setFormErrors ] = useState<ErrorInfoType>(initialErrors);
  const [ submitModal, setSubmitModal ] = useState<boolean>(false);

  const handleChange: HandleChangeType = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  };
  const validate: ValidateType = (values) => {
    const errors: ErrorInfoType = { mailaddress: "" };
    const regax: RegExp = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;

    if (!values.mailaddress || !regax.test(values.mailaddress)) {
      errors.mailaddress = "※メールアドレスを入力してください";
    };
    return errors;
  };
  const handleSubmit: HandleSubmitType = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
  };
  const toggleSubmitModal: ToggleModalType = () => {
    setSubmitModal(true);
  };
  
  return (
    <div className="w-full h-screen flex items-center justify-center bg-[#333333]">
      <form className="bg-[#f6f2f2] w-[330px] md:w-[450px] p-10 rounded-[10px] border-[1.5px] border-solid border-[#dfdfdf]" onSubmit={(e) => handleSubmit(e)}>
        <div className="flex flex-col items-center">
          <h1 className="font-bold text-[30px]">パスワードリセット</h1>
        </div>
        <p className="text-sm md:text-base mt-4">
        登録されているメールアドレスを入力してください。
        パスワードをリセットするためのリンクをお送りします。
        </p>
        <div className="flex flex-col ietms-center justify-evenly h-[280px] md:h-[330px]">
          <div className="flex flex-col w-full">
            <label className="text-[13px] md:text-[15px] font-bold mb-[3px]">メールアドレス <span className="text-red-500 font-normal">{formErrors.mailaddress}</span></label>
            <input
            type="text"
            placeholder="メールアドレス"
            name="mailaddress"
            onChange={(e) => handleChange(e)}
            className="border border-solid border-gray-500 p-3 rounded-md text-[13px] md:text-[15px]"
            />
          </div>
          <button className="bg-blue-500 w-full border-none rounded-[5px] px-[30px] py-[10px] text-white text-[15px] cursor-pointer transision duration-200 hover:bg-blue-700" onClick={toggleSubmitModal}>送信する</button>

          {/* {formErrors.mailaddress === "" && submitModal && (
            <div className={css.submitModal}>
                <div className={css.submitModalContent}>
                  <p className={css.submitText}>
                    メールを送信しました。メールをご確認の上、手順にそってパスワードをリセットしてください。
                  </p>
                </div>
            </div>
          )} */}

          <Link to="/" className="text-blue-700 underline text-[13px] md:text-[15px] text-center md:-mb-5">ログイン画面に戻る</Link>
          <Link to="/signup" className="text-blue-700 underline text-[13px] md:text-[15px] text-center">新規アカウント登録はこちら</Link> 
        </div>
      </form>
    </div>
  )
}

export default Reset