import employees from "../../../db/employees"
import EmployeeHeader from "../../components/headers/EmployeesHeader"
import Employee from "./Employee"

const EmployeesList = () => {
  const fromPath: string = "/home"
  return (
    <div className="w-full min-h-screen bg-[#333333] pb-10">
      <EmployeeHeader fromPath={fromPath} />
      <div className="mt-[50px] text-center">
        <h1 className="text-[#FCD43C] font-serif text-xl md:text-3xl ">従業員一覧</h1>
      </div>
      <div className="flex flex-col items-center justify-center mt-8 md:mt-10 lg:mt-16 pb-20">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 w-full">
          {employees.map((employee) => (
            <Employee key={employee.employeeNumber} employeeName={employee.employeeName} placeOfEmployment={employee.placeOfEmployment} dateOfJoin={employee.dateOfJoin} />
          ))}
        </div>
        <div className="flex items-center justify-center gap-5 mt-[50px] mb-[50px]">
          <button className="py-[20px] px-[55px] text-[#171717] border-none rounded-[10px] text-base md:text-lg font-bold font-serif cursor-pointer transision duration-300 bg-[#FCD43C] hover:opacity-70">従業員を追加する</button>
        </div>
      </div>
    </div>
  )
}

export default EmployeesList