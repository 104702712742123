import { ToggleMenuType } from '../../../components/common/Type';
import { Bars2Icon } from '@heroicons/react/24/solid';
import Menu from '../../../components/common/Menu';
import { useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { NavigateFunction, useNavigate } from 'react-router-dom';

const ReturnedListHeader = ({ fromPath }: { fromPath: string }) => {

  const [ menu, setMenu ] = useState<boolean>(false);
  const toggleMenu: ToggleMenuType = () => {
    setMenu(!menu);
  }

  const navigate: NavigateFunction = useNavigate();

  const toRoot = () => {
    navigate(fromPath);
  }

  return (
    <header className="bg-[#171717] text-white sticky top-0 w-full py-5 md:py-7 lg:py-7 z-10">
      <div className="flex items-center">
        <div onClick={toRoot} className="ml-3 md:ml-20">
          <h3 className="font-serif cursor-pointer text-3xl md:text-4xl flex items-center"><ChevronLeftIcon className="size-10" />NeutCode</h3>
        </div>
        <span className="flex-1"></span>
        <div className="mr-3 md:mr-20">
          <Bars2Icon className="w-10 h-10 md:w-16 md:h-16 cursor-pointer transision duration-300" onClick={toggleMenu}></Bars2Icon>
        </div>
        {menu && (
          <Menu toggleMenu={toggleMenu}/>
        )}
      </div>
    </header>
  )
}

export default ReturnedListHeader