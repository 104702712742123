import { ReactNode } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

const Button = ({ btnTitle, path, children }: { btnTitle: string, path: string, children: ReactNode }) => {

  const navigate: NavigateFunction = useNavigate();

  const toRoot = () => {
    navigate(path, { state: { from: "/home" } });
  }

  return (
    <button className="flex flex-col items-center gap-2 border-2 border-black bg-[#FCD43C] text-[#171717] rounded-[10px] py-[15px] px-[60px] mt-[45px] text-[20px] md:py-[20px] md:px-[100px] md:mt-[50px] md:text-[25px] font-bold font-serif cursop-pointer transision duration-300 hover:opacity-70" onClick={toRoot}>
      {children}
      {btnTitle}
    </button>
  )
}

export default Button