const borrowedItems = [
  {
    itemId: 1,
    itemName: "MacBook Pro",
    borrower: "NeutCode株式会社",
    checkoutdate: "2024/01/01",
    locationOfUse: "自宅",
    imagePath: "./images/sample.jpeg",
  },
  {
    itemId: 2,
    itemName: "Ipad Air",
    borrower: "NeutCode株式会社",
    checkoutdate: "2024/01/01",
    locationOfUse: "自宅",
    imagePath: "./images/sample2.jpg",
  },
  {
    itemId: 3,
    itemName: "Apple Studio Display",
    borrower: "NeutCode株式会社",
    checkoutdate: "2024/01/01",
    locationOfUse: "自宅",
    imagePath: "./images/sample3.jpg",
  },
]

export default borrowedItems;