import { NavigateFunction, useNavigate } from "react-router-dom";
import "animate.css";

const Complete = () => {

  const navigate: NavigateFunction = useNavigate();

  return (
    <div className="w-full h-screen top-0 left-0 bottom-0 right-0 fixed z-30">
      <div className="bg-[rgba(49,49,49,0.8)] w-screen h-screen top-0 left-0 bottom-0 right-0 fixed"></div>
      <div className="w-full h-full mt-[80px] fixed bg-[#333333] border-[3px] border-solid border-[#FFFFFF] rounded-md transision duration-700 overflow-scroll [-ms-overflow-style:none] [scrollbar-width:none] animate__animated animate__fadeInUp pb-10">
        <div className="flex flex-col items-center justify-center mt-[50px] md:mt-[70px] lg:mt-[90px] mb-[70px]">
          <div className="mb-8 md:mb-20">
            <h1 className="text-[#FFFFFF] font-bold font-serif text-xl md:text-3xl lg:text-5xl">変更を保存しました！</h1>
          </div>
          <div>
            <img src="./images/good.jpg" className="text-center w-[300px] h-[300px] md:w-[600px] md:h-[500px] lg:w-[700px] lg:h-[550px]" />
          </div>
          <div className="mt-[30px]">
            <p className="text-[#FFFFFF] items-center text-base md:text-2xl lg:text-3xl font-bold font-serif">
              変更したアイテムは「借入アイテム一覧」<br/>
              から確認できます。
            </p>
          </div>
          <button className="py-[15px] px-[50px] md:py-[30px] md:px-[70px] lg:py-[45px] lg:px-[90px] mt-10 text-[#171717] border-none rounded-[10px] text-base md:text-xl lg:text-3xl font-bold font-serif cursor-pointer transision duration-300 bg-[#FCD43C] hover:opacity-70" onClick={() => navigate("/home")}>ホームへ戻る</button>
        </div>
      </div>
    </div>
  );
}

export default Complete