import { UserCircleIcon } from "@heroicons/react/24/solid";
import Header from "../../../components/common/Header"
import { useLocation, useNavigate } from "react-router-dom"
import EmployeeDetailHeader from "../../components/headers/EmployeeDetailHeader";

const EmployeeDetail = () => {
  const navigate = useNavigate();
  const fromPath: string = "/employees";
  const location = useLocation();

  const { employeeName, placeOfEmployment, dateOfJoin } = location.state;

  return (
    <div className="w-full min-h-screen bg-[#333333] pb-10">
      <EmployeeDetailHeader fromPath={fromPath} />
      <div className="mt-[50px] text-center">
        <h1 className="text-[#FCD43C] font-serif text-xl md:text-3xl ">従業員詳細</h1>
      </div>
      <div className="flex flex-col items-center justify-center mt-10">
        <UserCircleIcon className="text-white w-20 h-20 md:w-28 md:h-28"  />
        <div className="mt-10">
          <ul className="text-white font-serif space-y-2 w-[300px] md:w-[360px] lg:w-[420px]">
            <li className="text-xl md:text-2xl lg:text-3xl flex items-center justify-between">
              <p>氏名:</p><p>{employeeName}</p>
            </li>
            <li className="text-xl md:text-2xl lg:text-3xl flex items-center justify-between">
              <p>就業先:</p><p>{placeOfEmployment}</p>
            </li>
            <li className="text-xl md:text-2xl lg:text-3xl flex items-center justify-between">
              <p>入社日:</p><p>{dateOfJoin}</p>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-7 md:gap-8 mt-10 md:mt-16 lg:mt-16">
        <button className="w-[240px] md:w-[280px] lg:w-[320px] py-[10px] md:py-[10px] lg:py-[18px] text-[#171717] border-none rounded-[5px] text-base md:text-xl font-bold font-serif cursor-pointer transision duration-300 bg-[#FCD43C] hover:opacity-70">この従業員のアイテムを見る</button>
        <button onClick={() => navigate("/employee-edit", { state: { employeeName, placeOfEmployment, dateOfJoin } })} className="w-[240px] md:w-[280px] lg:w-[320px] py-[10px] px-[30px] md:py-[10px] md:px-[45px] lg:py-[18px] lg:px-[60px] text-[#171717] border-none rounded-[5px] text-base md:text-xl font-bold font-serif cursor-pointer transision duration-300 bg-slate-300 hover:opacity-70">編集する</button>
        <button onClick={() => navigate("/employee-delete", { state: { employeeName, placeOfEmployment, dateOfJoin } })} className="w-[240px] md:w-[280px] lg:w-[320px] py-[10px] px-[30px] md:py-[10px] md:px-[45px] lg:py-[18px] lg:px-[60px] text-[#171717] border-none rounded-[5px] text-base md:text-xl font-bold font-serif cursor-pointer transision duration-300 bg-red-400 hover:opacity-70">削除する</button>
        <button onClick={() => navigate("/employees")} className="w-[240px] md:w-[280px] lg:w-[320px] py-[10px] px-[30px] md:py-[10px] md:px-[45px] lg:py-[18px] lg:px-[60px] text-[#171717] border-none rounded-[5px] text-base md:text-xl font-bold font-serif cursor-pointer transision duration-300 bg-gray-500 hover:opacity-70">戻る</button>
      </div>
    </div>
  )
}

export default EmployeeDetail