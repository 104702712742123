import { useLocation, useNavigate } from "react-router-dom"
import Header from "../../../../components/common/Header"

const Delete = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const { itemName, borrower, checkoutdate, locationOfUse, imagePath } = location.state;

  return (
    <div className="w-full min-h-screen bg-[#333333] pb-10">
      <Header />
      <div className="mt-[50px] text-center">
        <h1 className="text-[#FCD43C] font-serif text-xl md:text-3xl">アイテムの削除</h1>
      </div>
      <div className="flex flex-col items-center justify-center mt-10">
        <div className="aspect-square border overflow-hidden size-72 md:size-[400px]">
          <img src={imagePath} className="size-full object-cover" />
        </div>
        <h1 className="text-white font-bold font-serif mt-2 lg:mt-4 text-lg md:text-2xl">{itemName}</h1>
      </div>
      <div className="mt-[30px] lg:mt-[45px] flex items-center justify-center">
        <p className="text-[#FFFFFF] items-center text-base md:text-xl font-bold font-serif">
          本当に削除しますか？<br/>
          削除したアイテムは復元できません
        </p>
      </div>
      <div className="flex flex-col items-center justify-center gap-7 mt-10 md:mt-12 lg:mt-16">
        <button onClick={() => navigate("/borrowlist")} className="w-[240px] md:w-[280px] py-[10px] px-[30px] md:py-[10px] md:px-[45px] text-[#171717] border-none rounded-[5px] text-base md:text-xl font-bold font-serif cursor-pointer transision duration-300 bg-red-400 hover:opacity-70">削除する</button>
        <button onClick={() => navigate("/edit", { state: { itemName, borrower, checkoutdate, locationOfUse, imagePath }})} className="w-[240px] md:w-[280px] py-[10px] px-[30px] md:py-[10px] md:px-[45px] text-[#171717] border-none rounded-[5px] text-base md:text-xl font-bold font-serif cursor-pointer transision duration-300 bg-slate-300 hover:opacity-70">編集画面に戻る</button>
      </div>
    </div>
  )
}

export default Delete