import { UserCircleIcon } from "@heroicons/react/24/solid"
import { useLocation, useNavigate } from "react-router-dom";
import { useReducer, useState } from "react";
import EditComplete from "./EditComplete";
import EmployeeEditHeader from "../../components/headers/EmployeeEditHeader";

type employeeData = {
  employeeName: string;
  placeOfEmployment: string;
  dateOfJoin: string;
};

const EmployeeEdit = () => {

  const navigate = useNavigate();
  const fromPath: string = "/employee-detail";
  const location = useLocation();
  const { employeeName, placeOfEmployment, dateOfJoin }: employeeData = location.state;

  const [ initEmployeeName, setInitEmployeeName ] = useState<string>(employeeName);
  const [ initPlaceOfEmployment, setInitPlaceOfEmployment ] = useState<string>(placeOfEmployment);
  const [ initDateOfJoin, setInitDateOfJoin ] = useState<string>(dateOfJoin);

  const [ completeModal, toggleCompleteModal ] = useReducer((state) => !state, false);

  return (
    <div className="w-full min-h-screen bg-[#333333] pb-10">
    <EmployeeEditHeader fromPath={fromPath} employeeName={employeeName} placeOfEmployment={placeOfEmployment} dateOfJoin={dateOfJoin} />
    <div className="mt-[50px] text-center">
      <h1 className="text-[#FCD43C] font-serif text-xl md:text-3xl ">従業員情報の編集</h1>
    </div>
    <div className="flex items-center justify-center mt-10 md:mt-20">
      <UserCircleIcon className="text-white w-20 h-20" />
    </div>
    <form className="mt-10 flex item-center justify-center">
      <ul className="text-white font-serif w-[300px] md:w-[360px] lg:w-[420px] space-y-5">
        <li className="text-lg md:text-xl lg:text-2xl flex items-center justify-between">
          <p>氏名</p>
          <div className="bg-[#33363d]">            
            <input
            type="text"
            placeholder="氏名"
            value={initEmployeeName}
            name="employeeName"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInitEmployeeName(e.target.value)}
            className="text-center border bg-transparent border-solid border-gray-500 py-2 rounded-md lg:p-5"
            />
          </div>
        </li>
        <li className="text-lg md:text-xl lg:text-2xl flex items-center justify-between">
          <p>就業先</p>
          <div className="bg-[#33363d]">            
            <input
            type="text"
            placeholder="就業先"
            value={initPlaceOfEmployment}
            name="placeOfEmployment"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInitPlaceOfEmployment(e.target.value)}
            className="text-center border bg-transparent border-solid border-gray-500 py-2 rounded-md lg:p-5"
            />
          </div>
        </li>
        <li className="text-lg md:text-xl lg:text-2xl flex items-center justify-between">
          <p>入社日</p>
          <div className="bg-[#33363d]">            
            <input
            type="text"
            placeholder="入社日"
            value={initDateOfJoin}
            name="checkoutdate"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInitDateOfJoin(e.target.value)}
            className="text-center border bg-transparent border-solid border-gray-500 py-2 rounded-md lg:p-5"
            />
          </div>
        </li>
      </ul>
    </form>
  <div className="flex flex-col items-center justify-center gap-7 mt-10 md:mt-12 lg:mt-16">
    <button onClick={toggleCompleteModal} className="w-[240px] md:w-[280px] lg:w-[320px] py-[10px] px-[30px] md:py-[10px] md:px-[45px] lg:py-[18px] lg:px-[60px] text-[#171717] border-none rounded-[5px] text-base md:text-xl font-bold font-serif cursor-pointer transision duration-300 bg-[#FCD43C] hover:opacity-70">保存する</button>
    <button onClick={() => navigate("/employee-detail", { state: { employeeName, placeOfEmployment, dateOfJoin }})} className="w-[240px] md:w-[280px] lg:w-[320px] py-[10px] px-[30px] md:py-[10px] md:px-[45px] lg:py-[18px] lg:px-[60px] text-[#171717] border-none rounded-[5px] text-base md:text-xl font-bold font-serif cursor-pointer transision duration-300 bg-gray-500 hover:opacity-70">キャンセルする</button>
  </div>
  {completeModal && (
      <EditComplete />
    )}
</div>
  )
}

export default EmployeeEdit