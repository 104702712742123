import { SignInType, ErrorInfoType, HandleChangeType, ValidateType, HandleSubmitType } from "./Type";
import { useState } from "react";
import { Link, useNavigate, NavigateFunction } from "react-router-dom";

const SignIn = () => {

  const navigate: NavigateFunction = useNavigate();

  const initialValue: SignInType = {
   mailaddress: "",
   password: ""
  };
  const initialErrors: ErrorInfoType = {
    mailaddress: "",
    password: ""
  };

  const [ formValues, setFormValues ] = useState<SignInType>(initialValue);
  const [ formErrors, setFormErrors ] = useState<ErrorInfoType>(initialErrors);

  const handleChange: HandleChangeType = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [ name ]: value });
  };
  const validate: ValidateType = (values) => {
    const errors: ErrorInfoType = { mailaddress: "", password: "" };
    const regax: RegExp = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    if (!values.mailaddress || !regax.test(values.mailaddress)) {
      errors.mailaddress = "※メールアドレスを入力してください";
    };
    if (!values.password) {
      errors.password = "※パスワードを入力してください";
    };
    return errors;
  };
  const handleSubmit: HandleSubmitType = (e) => {
    e.preventDefault();
    // 登録情報の送信
    // バリデーションテェック
    setFormErrors(validate(formValues));
  };

  return (
    <div className="w-full h-screen flex items-center justify-center bg-[#333333]">
      <form className="bg-[#f6f2f2] w-[330px] md:w-[450px] p-10 rounded-[10px] border-[1.5px] border-solid border-[#dfdfdf]" onSubmit={(e) => handleSubmit(e)}>
        <h1 className="text-center text-[40px] [letter-spacing:1px] font-bold">NeutCode</h1>
        <div className="flex flex-col ietms-center justify-evenly h-[350px] md:h-[400px] lg:h-[450px]">
          <div className="flex flex-col w-full">
            <label className="text-[13px] md:text-[15px] font-bold mb-[3px]">メールアドレス <span className="text-red-500 font-normal">{formErrors.mailaddress}</span></label>
            <input
              type="text"
              placeholder="メールアドレス"
              name="mailaddress"
              onChange={(e) => handleChange(e)}
              className="border border-solid border-gray-500 p-3 rounded-md text-[13px] md:text-[15px]"
            />
          </div>
          <div className="flex flex-col w-full">
            <label className="text-[13px] md:text-[15px] font-bold mb-[3px]">パスワード <span className="text-red-500 font-normal">{formErrors.password}</span></label>
            <input
              type="text"
              placeholder="パスワード"
              name="password"
              onChange={(e) => handleChange(e)}
              className="border border-solid border-gray-500 p-3 rounded-md text-[13px] md:text-[15px]"
            />
          </div>
          <button className="bg-blue-500 w-full mt-[30px] border-none rounded-[5px] px-[30px] py-[10px] text-white text-[15px] cursor-pointer transision duration-200 hover:bg-blue-700">ログイン</button>
          <Link to="/reset" className="text-purple-700 underline mt-3 text-center text-[12px] md:text-[15px]">パスワードを忘れた場合</Link>
          <p className="mt-[10px] text-center text-[12px] md:text-[15px]">アカウントをお持ちでないですか？ <Link to="/signup" className="text-blue-700 underline">登録する</Link></p>
        </div>
      </form>
    </div>
  );
}

export default SignIn