import borrowedItems from "../../../db/borrowedItems";
import BorrowListHeader from "../../components/headers/BorrowListHeader";
import BorrowedItem from "./BorrowedItem";

const BorrowList = () => {
  const fromPath: string = "/home"

  return (
    <div className="w-full h-full bg-[#333333]">
      <BorrowListHeader fromPath={fromPath} />
      <div className="mt-[50px] text-center">
        <h1 className="text-[#FCD43C] font-serif text-xl md:text-3xl ">借入アイテム一覧</h1>
      </div>
      <div className="flex items-center justify-center mt-8 md:mt-10 lg:mt-16 pb-20">
        <div className="grid grid-cols-1 gap-10">
          {borrowedItems.map((borrowedItem) => (
            <BorrowedItem key={borrowedItem.itemId} { ...borrowedItem } />
          ))}
        </div>
      </div>
    </div>
  );
}

export default BorrowList