import { useLocation } from "react-router-dom";
import ReturnedListHeader from "../../components/headers/ReturnedListHeader"

const ReturnedList = () => {

  const location = useLocation();
  const fromPath: string = location.state?.from;

  return (
    <div className="min-h-screen w-full bg-[#333333] pb-10">
      <ReturnedListHeader fromPath={fromPath} />
      <div className="mt-[50px] text-center">
        <h1 className="text-[#FCD43C] font-serif text-xl md:text-3xl">返却済アイテム一覧</h1>
      </div>
      <div className="flex items-center justify-center mt-8 md:mt-10 lg:mt-12">
        <div className="grid grid-cols-2 gap-10 md:gap-16 lg:gap-32">
          <div>
            <div className="aspect-square border overflow-hidden size-32 md:size-48 lg:size-80">
              <img src="./images/sample4.jpg" className="size-full object-cover" />
            </div>
            <p className="text-white font-serif text-sm md:text-lg lg:text-2xl text-center mt-1">MacBook Air</p>
          </div>
          <div>
            <div className="aspect-square border overflow-hidden size-32 md:size-48 lg:size-80">
              <img src="./images/sample5.jpg" className="size-full object-cover" />
            </div>
            <p className="text-white font-serif text-sm md:text-lg lg:text-2xl text-center mt-1">Ipad Mini6</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReturnedList