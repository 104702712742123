import { UserCircleIcon } from "@heroicons/react/24/solid"
import { useLocation, useNavigate } from "react-router-dom";
import EmployeeDeleteHeader from "../../components/headers/EmployeeDeleteHeader";

const EmployeeDelete = () => {

  const navigate = useNavigate();
  const fromPath: string = "/employee-delete";
  const location = useLocation();

  const { employeeName, placeOfEmployment, dateOfJoin } = location.state;

  return (
    <div className="w-full min-h-screen bg-[#333333] pb-10">
      <EmployeeDeleteHeader fromPath={fromPath} employeeName={employeeName} placeOfEmployment={placeOfEmployment} dateOfJoin={dateOfJoin}/>
      <div className="mt-[50px] text-center">
        <h1 className="text-[#FCD43C] font-serif text-xl md:text-3xl ">従業員の削除</h1>
      </div>
      <div className="flex flex-col items-center justify-center mt-10">
      <UserCircleIcon className="text-white w-20 h-20 md:w-28 md:h-28"  />
        <h1 className="text-white font-bold font-serif mt-2 lg:mt-4 text-lg md:text-2xl lg:text-3xl">{employeeName}</h1>
      </div>
      <div className="mt-[30px] lg:mt-[45px] flex items-center justify-center">
        <p className="text-[#FFFFFF] items-center text-base md:text-2xl lg:text-3xl font-bold font-serif">
          本当に削除しますか？<br/>
          削除した従業員は復元できません
        </p>
      </div>
      <div className="flex flex-col items-center justify-center gap-7 mt-10 md:mt-12 lg:mt-16">
        <button onClick={() => navigate("/employees")} className="w-[240px] md:w-[280px] lg:w-[320px] py-[10px] px-[30px] md:py-[10px] md:px-[45px] lg:py-[18px] lg:px-[60px] text-[#171717] border-none rounded-[5px] text-base md:text-xl font-bold font-serif cursor-pointer transision duration-300 bg-red-400 hover:opacity-70">削除する</button>
        <button onClick={() => navigate("/employee-detail", { state: { employeeName, placeOfEmployment, dateOfJoin }})} className="w-[240px] md:w-[280px] lg:w-[320px] py-[10px] px-[30px] md:py-[10px] md:px-[45px] lg:py-[18px] lg:px-[60px] text-[#171717] border-none rounded-[5px] text-base md:text-xl font-bold font-serif cursor-pointer transision duration-300 bg-gray-500 hover:opacity-70">キャンセルする</button>
      </div>
    </div>
  )
}

export default EmployeeDelete