const employees = [
  {
    employeeNumber: 1,
    employeeName: "山田太郎",
    placeOfEmployment: "NeutCode株式会社",
    dateOfJoin: "2023/09/01",
  },
  {
    employeeNumber: 2,
    employeeName: "鈴木花子",
    placeOfEmployment: "NeutCode株式会社",
    dateOfJoin: "2024/04/01",
  },
  {
    employeeNumber: 1,
    employeeName: "John Smith",
    placeOfEmployment: "NeutCode株式会社",
    dateOfJoin: "2024/05/01",
  },
]

export default employees;