import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  itemId: number;
  itemName: string;
  borrower: string;
  checkoutdate: string;
  locationOfUse: string;
  imagePath: string;
};

const BorrowedItem = ( props: Props ) => {

  const { itemId, itemName, borrower, checkoutdate, locationOfUse, imagePath } = props;

  const navigate = useNavigate();
  const fromPath = "/borrowlist";

  return (
  <div className="bg-white/10 px-14 pt-10 pb-10 md:px-32 md:pt-12 md:pb-16 flex items-center justify-center flex-col">
    <div className="aspect-square border overflow-hidden size-60 md:size-80">
      <img src={imagePath} className="size-full object-cover" />
    </div>
    <div className="flex items-center justify-center gap-5 mt-8 lg:mt-10">
      <button onClick={() => navigate("/detail", { state: { itemName, borrower, checkoutdate, locationOfUse, imagePath, fromPath } })} className="py-[5px] px-[30px] md:py-[10px] md:px-[45px] lg:py-[18px] lg:px-[60px] text-[#171717] border-none rounded-[5px] text-base md:text-xl font-bold font-serif cursor-pointer transision duration-300 bg-gray-300 hover:opacity-70">詳細</button>
      <button onClick={() => navigate("/return", { state: { fromPath } })} className="py-[5px] px-[30px] md:py-[10px] md:px-[45px] lg:py-[18px] lg:px-[60px] text-[#171717] border-none rounded-[5px] text-base md:text-xl font-bold font-serif cursor-pointer transision duration-300 bg-[#FCD43C] hover:opacity-70">返却する</button>
    </div>
  </div>
  )
}

export default BorrowedItem