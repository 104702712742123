import { UserCircleIcon } from "@heroicons/react/24/solid"
import MypageHeader from "../../components/headers/MypageHeader"

const Mypage = () => {
  const fromPath: string = "/home";

  return (
    <div className="w-full min-h-screen bg-[#333333] pb-10">
      <MypageHeader fromPath={fromPath} />
      <div className="mt-[50px] text-center">
        <h1 className="text-[#FCD43C] font-serif text-xl md:text-3xl ">マイページ</h1>
      </div>
      <div className="mt-8 md:mt-10 lg:mt-16">
        <div className="bg-white/10 w-full h-40 md:h-52 lg:h-60 flex items-center justify-center">
          <div className="flex flex-col items-center gap-1">
            <UserCircleIcon className="w-20 h-20 md:w-28 md:h-28 lg:w-36 lg:h-36 text-white" />
            <div className="text-white text-base md:text-lg lg:text-2xl font-bold font-serif space-y-1">
              <p>山田太郎</p>
            </div>
          </div>
        </div>
        <div className="mt-5 lg:mt-10 flex item-center justify-center gap-5 md:gap-16">
          <div className="bg-white/10 p-5 md:p-20 lg:p-32 h-28 md:h-44 lg:h-56 flex items-center justify-center">
            <div className="flex flex-col items-center gap-1">
              <p className="text-white text-sm md:text-base lg:text-xl font-bold font-serif">借入中のアイテム数</p>
              <p className="text-white text-base md:text-lg font-bold font-serif space-y-1">3</p>
            </div>
          </div>
          <div className="bg-white/10 p-5 md:p-20 lg:p-32 h-28 md:h-44 lg:h-56 flex items-center justify-center">
            <div className="flex flex-col items-center gap-1">
              <p className="text-white text-sm md:text-base lg:text-lg font-bold font-serif">返却済のアイテム数</p>
              <p className="text-white text-base md:text-lg font-bold font-serif space-y-1">2</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mypage