import { ItemInfoType, ErrorInfoType, HandleChangeType, ValidateType, HandleSubmitType } from "./Type";
import Check from "./Check";
import Complete from "./Complete";
import { useReducer, useRef, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { PhotoIcon } from "@heroicons/react/24/solid";
import { useGetImageUrl } from "./useGetImageUrl";
import InputImage from "./InputImage";
import AddHeader from "../../components/headers/AddHeader";

const Add = () => {

  const navigate: NavigateFunction = useNavigate();
  const fromPath: string = "/home"

  const initialValues: ItemInfoType = {
    checkoutDate: "",
    itemName: "",
    borrower: "",
    locationOfUse: ""
  };
  const initialErrors: ErrorInfoType = {
    checkoutDate: "",
    itemName: "",
    borrower: "",
    locationOfUse: ""
  };

  const [ formValues, setFormValues ] = useState<ItemInfoType>(initialValues);
  const [ formErrors, setFormErrors ] = useState<ErrorInfoType>(initialErrors);
  const [ checkModal, toggleCheckModal ] = useReducer((state) => !state, false);
  const [ completeModal, toggleCompleteModal ] = useReducer((state) => !state, false);

  const handleChange: HandleChangeType = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [ name ]: value })
  };
  const validate: ValidateType = (values) => {
    const errors: ErrorInfoType = { checkoutDate: "", itemName: "", borrower: "", locationOfUse: "" };
    if (!values.checkoutDate) {
      errors.checkoutDate = "※レンタル日を入力してください";
    };
    if (!values.itemName) {
      errors.itemName = "※アイテム名を入力してください";
    };
    if (!values.borrower) {
      errors.borrower = "※貸出元を入力してください";
    };
    if (!values.locationOfUse) {
      errors.locationOfUse = "※使用場所を入力してください";
    };
    return errors;
  };
  const handleSubmit: HandleSubmitType = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setImageError(imgValidate(imageFile));
  };

    // 添付されたファイルをchangeイベントで取得し、stateとして状態管理する
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [ imageFile, setImageFile ] = useState<File | null>(null);
  
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if ( e.currentTarget?.files && e.currentTarget.files[0] ) {
        const targetFile = e.currentTarget.files[0];
        setImageFile(targetFile);
      }
    };

    const [ imageError, setImageError ] = useState<string>("");

    const imgValidate = (value: File | null) => {
      let imgError = "";
      if (!value) {
        imgError = "※写真を登録してください" 
      };
      return imgError;
    }
  
    // state(imageFile)が更新されたら、画像URLを作成する
    const { imageUrl } = useGetImageUrl({ file: imageFile });

  return (
    <div className="h-screen w-full flex flex-col items-center bg-[#333333] overflow-scroll [-ms-overflow-style:none] [scrollbar-width:none]">
      <AddHeader fromPath={fromPath} />
      <div className="mt-[50px]">
        <h1 className="text-[#FCD43C] text-3xl lg:text-5xl font-serif">アイテム登録</h1>
      </div>
      <form className="mt-[50px] font-serif" onSubmit={(e) => handleSubmit(e)}>
        <div className="flex flex-col items-center mt-[30px] text-white">
          <h2 className="text-base lg:text-2xl text-[#FCD43C]">1.レンタル品の写真を登録してください</h2>
          <span className="text-red-500 font-bold mt-1">{imageError}</span>
          <label className="flex items-center justify-center border-[2px] border-dashed border-white w-[300px] h-[250px] md:w-[450px] md:h-[350px] lg:w-[550px] lg:h-[450px] relative transision duration-300 hover:text-[#FCD43C] mt-[20px]" htmlFor="imageId">
            {imageUrl && imageFile ? (
              <img
                src={imageUrl}
                alt="アップロード画像"
                className="size-full object-cover"
              />
            ) : (
              <div className="flex flex-col justify-center items-center">
                <p>写真をアップロードしてください</p>
                <PhotoIcon className="w-20 h-20" />
              </div>
            )}
            <InputImage ref={fileInputRef} id="imageId" onChange={(e) => { handleFileChange(e); handleChange(e) }} />
          </label>
        </div>
        <div className="flex flex-col items-center mt-[50px]">
          <h2 className="text-base lg:text-2xl text-[#FCD43C]">2.情報を入力してください</h2>
          <div className="mt-[10px]">
            <div className="flex flex-col w-[300px] md:w-[360px] lg:w-[450px]">
              <label className="text-xs md:text-base font-bold mt-[10px] text-white">レンタル日 <span className="text-red-500 ml-1">{formErrors.checkoutDate}</span></label>
              <input
                type="date"
                placeholder="レンタル日"
                name="checkoutDate"
                onChange={(e) => handleChange(e)}
                className="border border-solid border-gray-500 p-3 rounded-md text-xs md:text-base font-bold text-gray-700"
              />
            </div>
            <div className="flex flex-col w-[300px] md:w-[360px] lg:w-[450px]">
              <label className="text-xs md:text-base font-bold mt-[10px] text-white">アイテム名 <span className="text-red-500 ml-1">{formErrors.itemName}</span></label>
              <input
                type="text"
                placeholder="品名"
                name="itemName"
                onChange={(e) => handleChange(e)}
                className="border border-solid border-gray-500 p-3 rounded-md text-xs md:text-base font-bold text-gray-700"
              />
            </div>
            <div className="flex flex-col w-[300px] md:w-[360px] lg:w-[450px]">
              <label className="text-xs md:text-base font-bold mt-[10px] text-white">貸出元 <span className="text-red-500 ml-1">{formErrors.borrower}</span></label>
              <input
                type="text"
                placeholder="貸出元"
                name="borrower"
                onChange={(e) => handleChange(e)}
                className="border border-solid border-gray-500 p-3 rounded-md text-xs md:text-base font-bold text-gray-700"
              />
            </div>
            <div className="flex flex-col w-[300px] md:w-[360px] lg:w-[450px]">
              <label className="text-xs md:text-base font-bold mt-[10px] text-white">使用場所 <span className="text-red-500 ml-1">{formErrors.locationOfUse}</span></label>
              <input
                type="text"
                placeholder="使用場所"
                name="locationOfUse"
                onChange={(e) => handleChange(e)}
                className="border border-solid border-gray-500 p-3 rounded-md text-xs md:text-base font-bold text-gray-700"
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center gap-5 mt-[50px] mb-[50px]">
          <button className="py-[15px] px-[50px] text-[#171717] border-none rounded-[20px] text-base font-bold font-serif cursor-pointer transision duration-300 bg-gray-300 hover:opacity-70" onClick={() => navigate("/home")}>戻る</button>
          <button className="py-[15px] px-[50px] text-[#171717] border-none rounded-[20px] text-base font-bold font-serif cursor-pointer transision duration-300 bg-[#FCD43C] hover:opacity-70" onClick={toggleCheckModal}>次へ</button>
        </div>
      </form>
      {imageError === "" && formErrors.checkoutDate === "" && formErrors.itemName === "" && formErrors.borrower === "" && formErrors.locationOfUse === "" && checkModal && (
        <Check formValues={formValues} imageUrl={imageUrl} toggleCheckModal={toggleCheckModal} toggleCompleteModal={toggleCompleteModal} />
      )}
      {completeModal && (
        <Complete />
      )}
    </div>
  );
}

export default Add