import { forwardRef } from "react";
import { InputImageType } from "./Type";

const InputImage = forwardRef<HTMLInputElement, InputImageType>(
  ({ onChange, id }, ref) => {
    return (
      <input
        ref={ref}
        id={id}
        type="file"
        accept="image/*"
        onChange={onChange}
        hidden
      />
    );
  }
);


export default InputImage;