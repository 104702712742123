import { ToggleMenuType } from "./Type";
import "animate.css";

const Menu = ({ toggleMenu }: { toggleMenu: ToggleMenuType }) => {

  return (
    <div className="w-full h-screen top-0 left-0 bottom-0 right-0 fixed z-10">
      <div className="bg-[rgba(49,49,49,0.8)] w-screen h-screen top-0 left-0  bottom-0 right-0 fixed" onClick={toggleMenu}></div>
        <div className="w-[250px] h-full fixed font-bold font-serif transision duration-700 animate__animated animate__fadeInLeft">
          <nav className="block w-[250px] md:w-[300px] lg:w-[400px] fixed bg-[#FCD43C] top-0 left-0 bottom-0">
            <div className="p-[25px]">
              <ul className="list-none m-0 p-0">
                <li className="m-0 border-b border-solid border-b-[#171717]"><a href="/home" className="block text-[#171717] text-lg md:text-xl p-[1rem] lg:p-[2.5rem] transision duration-200 hover:bg-[#cbb24c]">ホーム</a></li>
                <li className="m-0 border-b border-solid border-b-[#171717]"><a href="/add" className="block text-[#171717] text-lg md:text-xl p-[1rem] lg:p-[2.5rem] transision duration-200 hover:bg-[#cbb24c]">アイテム登録</a></li>
                <li className="m-0 border-b border-solid border-b-[#171717]"><a href="/borrowlist" className="block text-[#171717] text-lg md:text-xl p-[1rem] lg:p-[2.5rem] transision duration-200 hover:bg-[#cbb24c]">借入アイテム一覧</a></li>
                <li className="m-0 border-b border-solid border-b-[#171717]"><a href="/returnedlist" className="block text-[#171717] text-lg md:text-xl p-[1rem] lg:p-[2.5rem] transision duration-200 hover:bg-[#cbb24c]">返却済アイテム一覧</a></li>
                <li className="m-0 border-b border-solid border-b-[#171717]"><a href="/employees" className="block text-[#171717] text-lg md:text-xl p-[1rem] lg:p-[2.5rem] transision duration-200 hover:bg-[#cbb24c]">従業員一覧</a></li>
                <li className="m-0 border-b border-solid border-b-[#171717]"><a href="mypage" className="block text-[#171717] text-lg md:text-xl p-[1rem] lg:p-[2.5rem] transision duration-200 hover:bg-[#cbb24c]">マイページ</a></li>
              </ul>
            </div>
          </nav>
      </div>
    </div>
  );
}

export default Menu;