import './App.css';
import { BrowserRouter as Router, Routes, Route }  from 'react-router-dom';
import SignIn from './app/pages/user/signin/SignIn';
import SignUp from './app/pages/user/signup/SignUp';
import Reset from './app/pages/user/reset/Reset';
import Home from "./app/pages/home/Home";
import Add from "./app/pages/add/Add";
import ReturnedList from './app/pages/returnedList/ReturnedList';
import BorrowList from './app/pages/borrowList/BorrowList';
import Detail from './app/pages/borrowList/Detail';
import Edit from './app/pages/borrowList/edit/Edit';
import Delete from './app/pages/borrowList/edit/Delete';
import Return from './app/pages/return/Return';
import Employees from './app/pages/employees/Employees';
import EmployeeDetail from './app/pages/employees/EmployeeDetail';
import EmployeeEdit from './app/pages/employees/EmployeeEdit';
import EmployeeDelete from './app/pages/employees/EmployeeDelete';
import Mypage from './app/pages/mypage/Mypage';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/reset" element={<Reset />} />
       </Routes>
       <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/add" element={<Add />} />
        <Route path="/borrowlist" element={<BorrowList />} />
        <Route path="/detail" element={<Detail />} />
        <Route path="return" element={<Return />} />
        <Route path="/edit" element={<Edit />} />
        <Route path="/delete" element={<Delete />} />
        <Route path="/returnedlist" element={<ReturnedList />} />
        <Route path="/employees" element={<Employees />} />
        <Route path="/employee-detail" element={<EmployeeDetail />} />
        <Route path="/employee-edit" element={<EmployeeEdit />} />
        <Route path="/employee-delete" element={<EmployeeDelete />} />
        <Route path="/mypage" element={<Mypage />} />
       </Routes>
    </Router>
  );

}

export default App;
