import { PhotoIcon } from "@heroicons/react/24/solid"
import InputImage from "../add/InputImage"
import { useReducer, useRef, useState } from "react";
import { useGetImageUrl } from "../add/useGetImageUrl";
import { HandleChangeType, HandleSubmitType, ReturnInfoType, ValidateType } from "./Types";
import { useNavigate } from "react-router-dom";
import Check from "./Check";
import Complete from "./Complete";
import ReturnHeader from "../../components/headers/ReturnHeader";

const Return = () => {

  const navigate = useNavigate();
  const fromPath: string = "/borrowlist";

  const initialValues: ReturnInfoType = {
    returnDate: "",
    remarks: ""
  };
  const initialErrors = {
    returnDate: "",
  };

  const [ formValues, setFormValues ] = useState(initialValues);
  const [ formErrors, setFormErrors ] = useState(initialErrors);
  const [ checkModal, toggleCheckModal ] = useReducer((state) => !state, false);
  const [ completeModal, toggleCompleteModal ] = useReducer((state) => !state, false);

  const handleChange: HandleChangeType = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [ name ]: value, remarks: e.target.value})
  };
  const validate: ValidateType = (values) => {
    const errors = { returnDate: "" };
    if (!values.returnDate) {
      errors.returnDate = "※返却日を入力してください";
    };
    return errors;
  };

  const handleSubmit: HandleSubmitType = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setImageError(imgValidate(imageFile));
  };

    // 添付されたファイルをchangeイベントで取得し、stateとして状態管理する
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [ imageFile, setImageFile ] = useState<File | null>(null);
  
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if ( e.currentTarget?.files && e.currentTarget.files[0] ) {
        const targetFile = e.currentTarget.files[0];
        setImageFile(targetFile);
      }
    };

    const [ imageError, setImageError ] = useState<string>("");

    const imgValidate = (value: File | null) => {
      let imgError = "";
      if (!value) {
        imgError = "※写真を登録してください" 
      };
      return imgError;
    }
  
    // state(imageFile)が更新されたら、画像URLを作成する
    const { imageUrl } = useGetImageUrl({ file: imageFile });

    console.log(formValues.remarks)

  return (
    <div className="h-screen w-full flex flex-col items-center bg-[#333333] overflow-scroll [-ms-overflow-style:none] [scrollbar-width:none]">
      <ReturnHeader fromPath={fromPath} />
      <div className="mt-[50px]">
        <h1 className="text-[#FCD43C] text-3xl lg:text-5xl font-serif">返却登録</h1>
      </div>
      <form className="mt-[50px] font-serif" onSubmit={(e) => handleSubmit(e)}>
        <div className="flex flex-col items-center mt-[30px] text-white">
          <h2 className="text-base lg:text-2xl text-[#FCD43C]">1.返却時のレンタル品の状態の写真を登録してください</h2>
          <span className="text-red-500 font-bold mt-1">{imageError}</span>
          <label className="flex items-center justify-center border-[2px] border-dashed border-white w-[300px] h-[250px] md:w-[450px] md:h-[350px] lg:w-[550px] lg:h-[450px] relative transision duration-300 hover:text-[#FCD43C] mt-[20px]" htmlFor="imageId">
            {imageUrl && imageFile ? (
              <img
                src={imageUrl}
                alt="アップロード画像"
                className="size-full object-cover"
              />
            ) : (
              <div className="flex flex-col justify-center items-center">
                <p>写真をアップロードしてください</p>
                <PhotoIcon className="w-20 h-20" />
              </div>
            )}
            <InputImage ref={fileInputRef} id="imageId" onChange={handleFileChange} />
          </label>
        </div>
        <div className="flex flex-col items-center mt-[50px]">
          <h2 className="text-base lg:text-2xl text-[#FCD43C]">2.情報を入力してください</h2>
          <div className="mt-[10px]">
            <div className="flex flex-col w-[300px] md:w-[360px] lg:w-[450px]">
              <label className="text-xs md:text-base font-bold mt-[10px] text-white">返却日 <span className="text-red-500 ml-1">{formErrors.returnDate}</span></label>
              <input
                type="date"
                placeholder="返却日"
                name="returnDate"
                onChange={(e) => handleChange(e)}
                className="border border-solid border-gray-500 p-3 rounded-md text-xs md:text-base font-bold text-gray-700"
              />
              <label className="text-xs md:text-base font-bold mt-[10px] text-white">備考</label>
              <textarea 
                id="content"
                placeholder="備考"
                onChange={(e) => handleChange(e)} 
                className="border-2 border-gray-500 w-full h-32 md:h-44 lg:h-60 lg:p-5 p-1 rounded-md mb-5 resize-none text-gray-700 text-xs md:text-base font-bold"/>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center gap-5 mt-[50px] mb-[50px]">
          <button onClick={() => navigate("/borrowlist")} className="py-[15px] px-[50px] text-[#171717] border-none rounded-[20px] text-base font-bold font-serif cursor-pointer transision duration-300 bg-gray-300 hover:opacity-70">戻る</button>
          <button onClick={toggleCheckModal} className="py-[15px] px-[50px] text-[#171717] border-none rounded-[20px] text-base font-bold font-serif cursor-pointer transision duration-300 bg-[#FCD43C] hover:opacity-70">次へ</button>
        </div>
      </form>
      {imageError === "" && formErrors.returnDate === "" && checkModal && (
        <Check formValues={formValues} imageUrl={imageUrl} toggleCheckModal={toggleCheckModal} toggleCompleteModal={toggleCompleteModal} />
      )}
      {completeModal && (
        <Complete />
      )}
    </div>
  )
}

export default Return