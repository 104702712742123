import Rule from "./modals/rule/Rule";
import PrivacyPolicy from "./modals/privacypolicy/PrivacyPolicy";
import { UserInfoType, ErrorInfoType, HandleChangeType, ValidateType, HandleSubmitType } from "./Type";
import { useReducer, useState } from "react";
import { Link } from "react-router-dom";

const SignUp = () => {

  const initialValues: UserInfoType = {
    mailaddress: "",
    password: "",
    repassword: "",
    agreeTerms: false
  };
  const initialErrors: ErrorInfoType = {
    mailaddress: "",
    password: "",
    repassword: "",
    agreeTerms: ""
  };

  const [ formValues, setFormValues ] = useState<UserInfoType>(initialValues);
  const [ formErrors, setFormErrors ] = useState<ErrorInfoType>(initialErrors);
  const [ ruleModal, toggleRuleModal ] = useReducer((state) => !state, false);
  const [ privacyPolicyModal, togglePrivacyPolicyModal ] = useReducer((state) => !state, false);
  
  const handleChange: HandleChangeType = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [ name ]: value });
  };
  const validate: ValidateType = (values) => {
    const errors: ErrorInfoType = { mailaddress: "", password: "", repassword: "", agreeTerms: "" };
    const regax: RegExp = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
    if (!values.mailaddress || !regax.test(values.mailaddress)) {
      errors.mailaddress = "※メールアドレスを入力してください";
    };
    if (!values.password) {
      errors.password = "※パスワードを入力してください";
    };
    if (!values.repassword) {
      errors.repassword = "※パスワードを再入力してください";
    } else if (!(formValues.password === formValues.repassword)) {
      errors.repassword = "※上のパスワードと異なっています";
    };
    if (!values.agreeTerms) {
      errors.agreeTerms = "※利用規約とプライバシーポリシーに同意してください";
    };
    return errors;
  };
  const handleSubmit: HandleSubmitType = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
  };
  
  return (
    <div className="w-full h-screen flex items-center justify-center bg-[#333333]">
      <form className="bg-[#f6f2f2] w-[350px] md:w-[450px] p-10 rounded-[10px] border-[1.5px] border-solid border-[#dfdfdf]" onSubmit={(e) => handleSubmit(e)}>
        <h1 className="text-center text-[40px] [letter-spacing:1px] font-bold">NeutCode</h1>
        <div className="flex flex-col ietms-center justify-evenly h-[430px] md:h-[400px] lg:h-[450px] mt-3">
          <div className="flex flex-col w-full">
            <label className="text-[13px] md:text-[15px] font-bold mb-[3px]">メールアドレス <span className="text-red-500 font-normal">{formErrors.mailaddress}</span></label>
            <input
            type="text"
            placeholder="メールアドレス"
            name="mailaddress"
            onChange={(e) => handleChange(e)}
            className="border border-solid border-gray-500 p-3 rounded-md text-[13px] md:text-[15px]"
            />
          </div>
          <div className="flex flex-col w-full">
            <label className="text-[13px] md:text-[15px] font-bold mb-[3px]">パスワード <span className="text-red-500 font-normal">{formErrors.password}</span></label>
            <input
            type="text"
            placeholder="パスワード"
            name="password"
            onChange={(e) => handleChange(e)}
            className="border border-solid border-gray-500 p-3 rounded-md text-[13px] md:text-[15px]"
            />
          </div>
          <div className="flex flex-col w-full">
            <label className="text-[13px] md:text-[15px] font-bold mb-[3px]">パスワード再確認 <span className="text-red-500 font-normal">{formErrors.repassword}</span></label>
            <input
            type="text"
            placeholder="確認のためもう一度入力してください"
            name="repassword"
            onChange={(e) => handleChange(e)}
            className="border border-solid border-gray-500 p-3 rounded-md text-[13px] md:text-[15px]"
            />
          </div>
          <div className="flex mt-[10px]">
            <input
            type="checkbox"
            checked={formValues.agreeTerms}
            onChange={(e) => setFormValues({ ...formValues, agreeTerms: e.target.checked })}
            />
            <div className="text-[12px] md:text-[15px] ml-2">
              <span className="cursor-pointer text-purple-700 underline" onClick={toggleRuleModal}>利用規約</span>
              と
              <span className="cursor-pointer text-purple-700 underline" onClick={togglePrivacyPolicyModal}>プライバシーポリシー</span>
              に同意する
            </div>
          </div>

          {ruleModal && (
            <Rule toggleRuleModal={toggleRuleModal} />
          )}
          {privacyPolicyModal && (
            <PrivacyPolicy togglePrivacyPolicyModal={togglePrivacyPolicyModal} />
          )}
        
          <p className="text-red-500 font-normal text-[12px] md:text-[15px]">{formErrors.agreeTerms}</p>
          <button className="bg-blue-500 w-full mt-[10px] border-none rounded-[5px] px-[30px] py-[10px] text-white text-[15px] cursor-pointer transision duration-200 hover:bg-blue-700">登録する</button>
          <p className="mt-[10px] text-center text-[13px] md:text-[15px]">アカウントをお持ちですか？ <Link to="/" className="text-blue-700 underline">ログインする</Link></p>
        </div>
      </form>
    </div>
  )
}

export default SignUp